// ES5 way
// exports.onClientEntry = () => {
// ES6 way
//import { PreviewStoreProvider } from 'gatsby-source-prismic';
import React from "react"
import { ThemeProvider } from '@mui/material/styles';
import theme from './src/gatsby-theme-material-ui-top-layout/theme';
// Import self-hosted Google fonts for faster first load
import "@fontsource/nunito/400.css"
import "@fontsource/nunito/600.css"
import "@fontsource/nunito/700.css"
import "@fontsource/nunito-sans/600.css"

/*export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}*/

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

export const wrapRootElement = ({ element }) => {
  return (
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
  );
}