import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const primaryBackgroundColor = '#F8F5F9';
//const outlinedButtonBackground = '#FFFFFF';
const whiteText = '#FFFFFF';
const formControlLabelColor = 'E4E3E3';
const akkaPurple = "#631C55"; // rgba(99, 28, 85, 1)
const akkaPink = "#EF6CA1"; // rgba(239, 108, 161, 1)
const akkaMint = "#4EBFA3" // rgba(78, 191, 163, 1)
const akkaLink = "#973A71";
const lightContrastText = "#FFF";
const info = "#47E847";

const contrastBg = akkaPurple;
const callToAction = akkaMint; //"#4BAB7A"; //
const callToActionStrong = "#137D47";
const callToActionDark = "#3C8761"

const spacing = 8;

let theme = createTheme({
    // TODO Default breakpoints we changed v4 -> v5. Values below are in line with v4
    // See: https://mui.com/guides/migration-v4/
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    spacing: spacing,
    /*shape: {
        borderRadius: 0, // TODO Find out why we cannot override this value in MuiMenu::paper?!?
    },*/
    typography: {
        // Rely on Google fonts, defaulting to Kite One. Override 
        //
        fontFamily: [
            "Nunito",
            "Nunito Sans",
            "Architects Daughter",
            "Roboto",
        ].join(','),
        fontSize: 14
    },
    palette: {
        mode: 'light',
        background: {
            // Default background for all surfaces unless overridden
            // Include <CSSBaseline> to apply default background color to <body> 
            default: primaryBackgroundColor
        },
        primary: {
            //light: will be calculated from palette.primary.main,
            main: akkaPurple,
            //dark: will be calculated from palette.primary.main,
            contrastText: lightContrastText
        },
        secondary: {
            //light: will be calculated from palette.primary.main,
            main: akkaPink, // Green
            //dark: secondaryGreen, //'#304ffe', // Blue
            contrastText: lightContrastText
        },
        navLinkColor: {
            main: contrastBg
        },
        calltoaction: {
            main: callToAction
        },
        success: {
            main: "#4caf50"
        },
        info: {
            main: info
        },
        danger: {
            main: "#f44336"
        },
        warning: {
            main: "#ff9800",
        },
        gray: {
            main: "#999999",
        },
        error: {
            main: '#ff5722', // "Deep orange"
        }
    },
    body: {
        margin: 0, // Remove the margin in all browsers.
        textAlign: "left",
        border: "2px dotted red",
        /*backgroundColor: theme.palette.background.default,
        '@media print': {
          // Save printer ink.
          backgroundColor: theme.palette.common.white,
        },
        overflow: "unset"*/
        img: {
            macWidth: "40px"
        }
    },
    //overrides: {
    components: {
        // Needed to set styles right in CMS-originated right text snippets
        MuiCssBaseline: {
            styleOverrides: {
                a: {
                    color: akkaLink,
                    fontWeight: 600,
                    textDecoration: "none",
                    "&:hover,&:focus": {
                        textDecoration: "underline",
                    },
                },
                body: { // In line with the defaults of previous versions of material-ui
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            }
        },
        //  We use Container to layout page sections with enough white space around them.
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: spacing,
                    margin: spacing,
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "transparent" // Where is this really documented?!
                },
                root: {
                    display: "flex",
                    border: 0,
                    borderRadius: 0,
                    padding: "0.625rem 0",
                    margin: 0,
                    //marginBottom: 0,
                    color: lightContrastText,
                    width: "100%",
                    backgroundColor: "#fff",
                    boxShadow:
                        "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
                    transition: "all 150ms ease 0s",
                    alignItems: "center",
                    flexFlow: "row nowrap",
                    justifyContent: "flex-start",
                    position: "relative",
                    zIndex: "unset"
                },
                positionSticky: { // The style for Akka app bar
                    zIndex: 1100
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    //...container,
                    minHeight: "50px",
                    flex: "1",
                    alignItems: "center",
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap: "nowrap",
                    width: "100%"
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: formControlLabelColor
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: { // Backdrop for the drawer
                    margin: 0,
                    padding: 0,
                    width: '7%',
                },
                paper: {
                    margin: 0,
                    width: '320px',
                    padding: spacing,
                    borderRadius: 0,
                    backgroundColor: akkaPurple
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                item: { // <Grid item... />
                    padding: spacing,
                    paddingLeft: spacing * 1.5,
                    paddingRight: spacing * 1.5,
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                    margin: 0,
                    paddingLeft: "0",
                    listStyle: "none",
                    paddingTop: "0",
                    paddingBottom: "0",
                    color: "inherit",
                },
            }
        },
        /* We use ListItem e.g. in our drawer menu*/
        MuiListItem: {
            styleOverrides: {
                root: {
                    float: "left",
                    color: "#FFF",
                    position: "relative",
                    //display: "block",
                    width: "auto",
                    margin: "0",
                    padding: "0",
                    display: "inline-flex", // Text and possible other elements on the same row
                    /*
                    [this.breakpoints.down("sm")]: {
                        width: "100%",
                        "&:after": {
                            width: "calc(100% - 30px)",
                            content: '""',
                            display: "block",
                            height: "1px",
                            marginLeft: "15px",
                            backgroundColor: "#e5e5e5"
                        }
                    }*/
                },
                divider: {
                    borderBottom: "1px solid #FFFFFF",
                },
                button: {
                    color: "#FFFFFF",
                    position: "relative",
                    borderRadius: 4,
                    margin: 2,
                    padding: "0.4375rem", //"0.9375rem",
                    paddingBottom: "0.2375rem",
                    paddingTop: "0.2375rem",
                    fontWeight: 400,
                    textTransform: "uppercase",
                    // lineHeight: "20px",
                    display: "inline-flex",
                    "&:hover,&:focus": {
                        background: "rgba(200, 200, 200, 0.6)",
                    },
                    "&$selected": {
                        color: akkaPurple,
                        background: "rgba(255, 255, 255, 0.9)",
                        "&:hover,&:focus": {
                            background: "rgba(255, 255, 255, 0.9)",
                        },
                    },
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: "#FFFFFF",
                    padding: spacing,
                    marginRight: spacing,
                    //fontSize: "32px", // No effect?!
                    textTransform: "uppercase",
                }
            }
        },
        MuiListItemButton: { // Used in navigation menu
            styleOverrides: {
                root: {
                    fontSize: "14px",
                    padding: spacing,
                    marginRight: spacing,
                    borderRadius: spacing * 0.5,
                    borderColor: "transparent",
                    borderWidth: 0,
                    color: "#fff",
                    cursor: "pointer",
                    display: "inline-block",
                    textTransform: "uppercase",
                    transition: "all .3s",
                    position: "relative",
                    overflow: "hidden",
                    zIndex: 1,
                    '&:hover': {
                        backgroundColor: "rgba(255,255,0255,0.25)",
                    },
                    "&.Mui-selected": {
                        backgroundColor: lightContrastText,
                        color: akkaPurple,
                        '&:hover': {
                            backgroundColor: lightContrastText,
                        },
                    }
                },
            }
        },
        MuiMenu: {
            styleOverrides: {
                /*paper: {
                    padding: spacing,
                    borderTopWidth: "4px",
                    borderTopStyle: "solid",
                    borderTopColor: akkaPink,
                    overflow: "visible",
                    //"&::before": {
                    "&.Mui-before": {
                        content: "''",
                        position: "absolute",
                        border: "10px solid transparent",
                        borderBottomColor: akkaPink,
                        top: "-calc(50% - 40px)",
                    },
                },*/
                list: {
                    display: "flex",
                    flexDirection: "column",
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    // From list item below
                    float: "left",
                    color: "inherit",
                    position: "relative",
                    display: "block",
                    width: "auto",
                    margin: "0",
                    padding: "0",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 2
                },
                containedPrimary: {
                    margin: '1em',
                    backgroundColor: callToAction, //callToAction,
                    color: whiteText,
                    borderColor: callToAction,
                    borderWidth: "2px",
                    borderStyle: "solid",
                    fontWeight: 'bold',
                    zIndex: 1,
                    transition: "0.3s ease-in",
                    '&:hover': {
                        //paddingRight: spacing*4,
                        backgroundColor: primaryBackgroundColor,
                        color: callToActionStrong,
                        borderColor: callToActionStrong,
                        borderWidth: "2px",
                        borderStyle: "solid",
                        '&:after': {
                            content: "'>>'",
                            position: "absolute",
                            opacity: 0,
                            top: 0,
                            zIndex: 300,
                            right: "-20px",
                        }
                    },
                    '&:after': {
                        backgroundColor: "red", //akkaPurple, //,
                        color: akkaPink,
                        content: "'>>'",
                        position: "absolute",
                        opacity: 0,
                        top: 0,
                        zIndex: 300,
                        right: "-20px",
                        transition: "0.3s linear"
                    },
                    /*'&:before': {
                        content: "''",
                        color: "black",
                        border: "2px solid magenta",
                        background: primaryBackgroundColor, //akkaPurple, //,
                        position: "absolute",
                        width: "0%",
                        top: 0,
                        left: 0,
                        height: "100%",
                        transition: "0.3s linear",
                        zIndex: 10,
                    },
                    '&:hover:before': {
                        //top: 0
                        width: "100%"
                    }*/
                },
                containedInherit: {
                    margin: '1em',
                    backgroundColor: info,
                    color: whiteText,
                    borderColor: info,
                    borderWidth: "2px",
                    borderStyle: "solid",
                    fontWeight: 'bold',
                    zIndex: 1,
                    transition: "0.3s ease-in",
                    '&:hover': {
                        //paddingRight: spacing*4,
                        backgroundColor: primaryBackgroundColor,
                        color: info,
                        borderColor: info,
                        borderWidth: "2px",
                        borderStyle: "solid",
                    },
                },
                outlined: {
                    //backgroundColor: outlinedButtonBackground, // Hwmm, how to make this live with the theme?!
                    borderColor: callToAction,
                    color: callToAction,
                    //fontWeight: 'bold',
                    /*'&:hover': {
                        //backgroundColor: callToAction,
                        //color: primaryMain
                        //fontWeight: 'bold',
                        //borderWidth: '2px'
                    }*/
                },
                containedSecondary: { // Mint background -> transition to pink font and border on hover
                    margin: '1em',
                    backgroundColor: callToAction, //callToAction,
                    color: whiteText,
                    borderColor: callToAction,
                    fontWeight: 'bold',
                    borderWidth: "2px",
                    borderStyle: "solid",
                    transition: "0.3s ease-in",
                    '&:hover': {
                        //paddingRight: spacing*4,
                        backgroundColor: primaryBackgroundColor,
                        color: akkaPink,
                        borderColor: akkaPink,
                        borderWidth: "2px",
                        borderStyle: "solid",
                    },
                    /*'&:hover:after': {
                        content: "'\\2192'",
                        position: "absolute",
                        right: spacing,
                        height: "100%",
                        zIndex: 100,
                    },*/
                },
                outlinedSecondary: { // Used on pro courses page
                    borderColor: "transparent",
                    borderWidth: 0,
                    //borderRadius: "50vh",
                    color: "#fff",
                    cursor: "pointer",
                    display: "inline-block",
                    //padding: ".75rem 1.25rem",
                    //borderRadius: "10rem",
                    textTransform: "uppercase",
                    //fontSize: "1rem",
                    //letterSpacing: ".1rem",
                    transition: "all .3s",
                    position: "relative",
                    overflow: "hidden",
                    zIndex: 1,
                    //transition: "color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1), background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1)",
                    '&:after': {
                        content: "''",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: akkaPurple,
                        borderColor: "transparent",
                        borderWidth: 0,
                        borderRadius: "10rem",
                        zIndex: -2,
                    },
                    '&:before': {
                        content: "''",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "0%",
                        height: "100%",
                        backgroundColor: "blue",
                        borderColor: "transparent",
                        borderWidth: 0,
                        transition: "all .3s",
                        borderRadius: "10rem",
                        zIndex: -1
                    },
                    '&:hover:before': {
                        width: "50%",
                    }
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    height: "100%", // These four...
                    display: "flex", // ...lines...
                    flexDirection: "column", // ...lines align card... 
                    justifyContent: "space-between", // ...buttons on the bottom.
                    borderRadius: "2px",
                    borderColor: 'rgba(0, 0, 0, 0.25)',
                    borderTopStyle: "solid",
                    borderTopWidth: "1px",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                    boxShadow: '0 5px 10px -5px rgba(0, 0, 0, 0.75)',
                    padding: 0,
                    margin: 0,
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: 0,
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: whiteText,
                    backgroundColor: contrastBg,
                    fontSize: "13px"
                }
            }
        }
    },
    status: {
        danger: 'green',
    }
});

theme = responsiveFontSizes(theme);

export default theme;